$ka-border-color: #F9FBFC !default;
$ka-color-base: #353C44 !default;
$ka-thead-color: #747D86 !default;

$ka-background-color: white !default;
$ka-input-background-color: null !default;
$ka-input-border-color: null !default;
$ka-input-color: null !default;
$ka-cell-color: $ka-color-base !default;
$ka-cell-hover-background-color: null !default;
$ka-cell-line-height: 29px !default;
$ka-cell-padding: 8px 20px !default;
$ka-column-resize-border-color: darken($ka-border-color, 10) !default;
$ka-filter-row-cell-padding: 0 20px 15px 20px;
$ka-font-size: 14px !default;
$ka-group-cell-padding: 8px 10px !default;
$ka-group-row-background-color: #F9FBFC !default;
$ka-group-row-border-color: white !default;
$ka-group-row-border-size: 1px !default;
$ka-icon-font-size: 10px !default;
$ka-input-validation-background-color: #FFE7E7 !default;
$ka-loading-backdrop-color: #ffffff88 !default;
$ka-loading-background-color-base: rgb(116,125,134) !default;
$ka-paging-index-active-background-color: #F1F5F7 !default;
$ka-paging-index-active-color: #747D86 !default;
$ka-paging-index-color: $ka-thead-color !default;
$ka-paging-pages-margin: 0;
$ka-paging-pages-padding: 0 10px;
$ka-row-border-size: 2px !default;
$ka-row-hover-background-color: null !default;
$ka-selected-border-color: darken($ka-border-color, 7) !default;
$ka-selecter-background-color: #F7FcFd !default;
$ka-summary-cell-padding: 12px 20px !default;
$ka-thead-background-color: #F1F5F7 !default;
$ka-thead-cell-padding: 15px 20px !default;
$ka-validation-background-color: #FF0C0C !default;
$ka-validation-color: white !default;
$ka-validation-message-font-size: 12px !default;
$ka-validation-message-padding: 0 10px !default;
$ka-header-cell-height: 47px !default;
