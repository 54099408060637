@import './static/icons.scss';
@import './static/themes/default.scss';


.ka{
  overflow: hidden;
  background-color: $ka-background-color;
  font-size: $ka-font-size;
  display: flex;
  flex-direction: column;
}
.ka-table-wrapper{
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.ka-table{
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ka-thead-background{
  background-color: $ka-thead-background-color;
}
.ka-thead-grouped-cell {
  height: $ka-header-cell-height;
  box-sizing: border-box;
}
.ka-thead-fixed {
  position: sticky;
}
@for $i from 0 through 5 {
  .ka-thead-row:nth-child(#{$i + 1}) {
    .ka-thead-fixed {
      top: $ka-header-cell-height * $i;
    }
  }
}
.ka-thead-cell{
  padding: $ka-thead-cell-padding;
  color: $ka-thead-color;
}
.ka-thead-cell-wrapper {
  display: flex;
}
.ka-thead-cell-content-wrapper{
  width: 100%;
}
.ka-thead-cell-content {
  width: 100%;
}
.ka-tbody{
  height: 100%;
}
.ka-thead-cell, .ka-cell{
  text-align: left;
}
.ka-cell{
  padding: $ka-cell-padding;
  line-height: $ka-cell-line-height;
  color: $ka-cell-color;
}
.ka-cell:hover{
  background-color: $ka-cell-hover-background-color;
}
.ka-tree-cell{
  display: flex;
}
.ka-icon{
  font-weight: 100;
  font-size: $ka-icon-font-size;
  font-family: 'icons';
}
.ka-input{
  max-width: 100%;
  background-color: $ka-input-background-color;
  border: 1px solid $ka-input-border-color;
  color: $ka-input-color;
}
.ka-cell-editor-validation-error {
  .ka-input{
    background: $ka-input-validation-background-color;
    border: 1px solid $ka-validation-background-color;
  }
}
.ka-validation-message-container{
  position: absolute;
}
.ka-validation-message{
  font-size: $ka-validation-message-font-size;
  color: $ka-validation-color;
  background-color: $ka-validation-background-color;
  padding: $ka-validation-message-padding;
  max-width: 200px;
}
.ka-empty-cell{
  width: 40px;
  min-width: 40px;
}
.ka-tree-empty-space{
  width: 20px;
}
.ka-tr {
  width: 100%;
}
.ka-no-data-row{
  height: 100px;
  text-align: center;
}
.ka-row{
  box-sizing: border-box;
  border-bottom: $ka-row-border-size solid $ka-border-color;
  border-top: $ka-row-border-size solid $ka-border-color;
}
.ka-row:hover{
  background-color: $ka-row-hover-background-color;
}
.ka-dragged-row{
  opacity: 0.5;
}
.ka-drag-over-row {
  box-shadow: inset 0 7px 0px -4px $ka-selected-border-color;
}
.ka-dragged-row ~ .ka-drag-over-row{
  box-shadow: inset 0 -7px 0px -4px $ka-selected-border-color;
}
.ka-drag-over-column {
  box-shadow: inset 7px 0 0px -4px $ka-selected-border-color;
}
.ka-dragged-column ~ .ka-drag-over-column{
  box-shadow: inset -7px 0 0px -4px $ka-selected-border-color;
}
.ka-row-selected {
  background-color: $ka-selecter-background-color;
}
.ka-group-row{
  background-color: $ka-group-row-background-color;
  box-sizing: border-box;
  border-bottom: $ka-group-row-border-size solid $ka-group-row-border-color;
  border-top: $ka-group-row-border-size solid $ka-group-row-border-color;
}
.ka-group-cell{
  padding: $ka-group-cell-padding;
}
.ka-group-cell-content{
  display: flex;
  align-items: center;
}
.ka-icon-group-arrow{
  padding: 10px 5px 10px 10px;
  margin-right: 5px;
  cursor: pointer;
}
.ka-icon-tree-arrow{
  padding: 0 10px 0 10px;
  margin-left: -10px;
  cursor: pointer;
}
.ka-icon-sort{
  margin-left: 3px;
}
.ka-pointer{
  cursor: pointer;
}
.ka-filter-row-cell{
  padding: $ka-filter-row-cell-padding;
}
.ka-loading{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $ka-loading-backdrop-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ka-loading-active{
  position: relative;
}
.ka-loading-icon{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: typing 1s linear infinite alternate;
  position: relative;
  left: -20px;
}
.ka-thead-cell-resize{
  user-select: none;
  width: 2px;
  cursor: col-resize;
  background-color: $ka-column-resize-border-color;
  position: relative;
  left: 19px;
}
.ka-thead-cell-resize:active:after{
  content: "";
  display: block;
  position: fixed;
  cursor: col-resize;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ka-thead-background:active{
  z-index: 1;
}
.ka-summary-cell{
  padding: $ka-summary-cell-padding;
}

@keyframes typing{
  0%{
    background-color: $ka-loading-background-color-base;
    box-shadow: 20px 0px 0px 0px transparentize($ka-loading-background-color-base, 0.8),
                40px 0px 0px 0px transparentize($ka-loading-background-color-base, 0.8);
  }
  25%{
    background-color: transparentize($ka-loading-background-color-base, 0.6);
    box-shadow: 20px 0px 0px 0px $ka-loading-background-color-base,
                40px 0px 0px 0px transparentize($ka-loading-background-color-base, 0.8);
  }
  75%{
    background-color: transparentize($ka-loading-background-color-base, 0.6);
    box-shadow: 20px 0px 0px 0px transparentize($ka-loading-background-color-base, 0.8),
                40px 0px 0px 0px $ka-loading-background-color-base;
  }
  100%{
    box-shadow: none;
  }
}
.ka-loading-text{
  margin-top: 15px;
  color: $ka-color-base;
}

.ka-paging-sizes-active {
  display: flex;
  justify-content: space-between;
}

.ka-paging-pages, .ka-paging-sizes {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: $ka-paging-pages-padding;
  margin: $ka-paging-pages-margin;
}

.ka-paging-pages {
  justify-content: flex-end;
}

.ka-paging-page-index, .ka-paging-size{
  cursor: pointer;
  padding: 5px;
  margin: 10px 5px;
  min-width: 18px;
  border-radius: 50%;
  text-align: center;
  color: $ka-paging-index-color;
  user-select: none;
}

.ka-paging-page-index-active, .ka-paging-size-active {
  background-color: $ka-paging-index-active-background-color;
  font-weight: bold;
  color: $ka-paging-index-active-color;
}
